import ApiService from "./ApiService";

export default class OrderService {
  static getAllFolderCount = () =>
    ApiService.get("/admin/get-all-folder-count");

  static getPhonenumberList = () =>
    ApiService.get("/admin/get-contact-customer-phone-number");

  static getOrdersList = ({ order }) =>
    ApiService.get(
      `/admin/get-orders-list/${order.orderName}?page=${order.page}&limit=${order.perPage}&delay=1`
    );

  static getOrderDetailsByOrderId = (orderId) =>
    ApiService.get(`/admin/get-orders-details/${orderId}`);

  static deleteOrdersData = (deleteData) =>
    ApiService.put(`/admin/change-order-status`, deleteData);

  static sendRefundMail = (refundData) =>
    ApiService.put("/admin/move-to-complete-refund", refundData);

  static uploadVisaDocument = (data) =>
    ApiService.post("/admin/upload-visa-document", data);

  static resendMail = (data) => ApiService.post("/admin/resend-mail", data);

  static ChangeOrderStatus = (data) =>
    ApiService.put(`/admin/change-order-status`, data);

  static defencePack = (orderId) =>
    ApiService.post("/admin/defence-pack-details", orderId);

  static GetDefencePackPdf = (data) =>
    ApiService.post("/admin/get-defence-pack-pdf", data, {
      responseType: "blob",
    });

  static printOrderInfo = (orderId) =>
    ApiService.post("/admin/print-order-info", orderId);

  static viewProcessOrder = (tmId) =>
    ApiService.get(`/admin/view-process-order/${tmId}`);

  static trackCustomerOrder = (orderData) =>
    ApiService.post(`/front/track-order`, orderData);

  static getDownloadHistory = (orderId) =>
    ApiService.post(`/front/get-download-history`, orderId);

  static searchOrder = (data) => ApiService.post(`/admin/search-order`, data);

  static getTwilioLog = (data) =>
    ApiService.get(
      `/admin/get-twilio-log?start=${data.start}&end=${data.end}&delay=1`
    );

  static getProcessingLog = (data) =>
    ApiService.get(
      `/admin/get-processing-log?start=${data.start}&end=${data.end}&delay=1`
    );

  static getDocumentLog = (data) =>
    ApiService.get(
      `/admin/get-document-log?start=${data.start}&end=${data.end}&delay=1`
    );

  static removeCustomerAllData = (data) =>
    ApiService.post("/admin/remove-customer-all-data", data);

  static encryptOpenSSL = (data) =>
    ApiService.post("/admin/get-encrpted-orderId", data);
  
  static getStateTerritoryDetails = (data) =>
    ApiService.post("/admin/get-state-territory-count", data);
    
}
