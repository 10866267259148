import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactDataTable from "../../../Components/ReactDataTable/ReactDataTable";
import { getTwilioLog } from "../../../redux/orderSlice";
import { StyledContainer, StyledOrderBlock, StyledPageTitle } from "./style";

const TwilioLog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const TwilioLogList = useSelector((state) => state?.order?.twilioLogList);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(
      getTwilioLog({
        start: page,
        end: limit,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          toast.success(`${res?.message}`, {
            className: "toast-message",
          });
        } else {
          toast.error(`${res?.message}`, {
            className: "toast-message",
          });
        }
      });
    document.title = "Twilio Log | USA Passport";
  }, [dispatch, navigate, page, limit]);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.Id,
      sortable: true,
    },
    {
      name: "Order Id",
      selector: (row) => row.orderId,
      sortable: true,
    },
    {
      name: "Customer Name",
      width: "15rem",
      selector: (row) => row.customerName,
      sortable: true,
    },
    {
      name: "Phone",
      width: "10rem",
      selector: (row) => row.phoneNumber,
      sortable: true,
    },
    {
      name: "Folder Name",
      width: "10rem",
      selector: (row) => row.folderName,
      sortable: true,
    },
    {
      name: "Link Type",
      width: "10rem",
      selector: (row) => row.linkType,
      sortable: true,
    },
    {
      name: "Message",
      width: "15rem",
      selector: (row) => row.message,
      sortable: true,
    },
    {
      name: "Date",
      width: "10rem",
      selector: (row) =>
        row?.TwilioDate ? moment(new Date(row?.TwilioDate)).format("MM-DD-YYYY") : null,
      sortable: true,
    },
  ];

  const tableData = TwilioLogList;

  return (
    <StyledContainer>
      <StyledPageTitle>
        <h1>Twilio Log</h1>
      </StyledPageTitle>
      <form>
        <StyledOrderBlock>
          <div className="table-block table-responsive">
            <ReactDataTable
              customColumns={columns}
              data={tableData}
              setPage={setPage}
              setLimit={setLimit}
              orderName="twiliolog"
              showCustomSearchInput={true}
              buttonWrapper={null}
              topButtonWrapper={null}
            />
          </div>
        </StyledOrderBlock>
      </form>
    </StyledContainer>
  );
};

export default TwilioLog;
