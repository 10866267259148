import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../pages/AdminLogin/Login";

const SignupLayout = (props) => (
  <div>
    <Routes>
      <Route path="/" element={<Login />} />
    </Routes>
  </div>
);

export default SignupLayout;
