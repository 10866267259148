import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoginContainer, StyledLogin, LoginLogo, FormContainer } from "./style";
import AdminLogo from "../../assests/images/logo.svg";
import RightArrow from "../../assests/images/right-arrow.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { authenticate } from "../../redux/authSlice";
import { toast } from "react-toastify";

const initialValues = {
  email: "",
  password: "",
};

const loginSchema = yup.object({
  email: yup.string().email().required("Please enter your email"),
  password: yup.string().min(5).required("Please enter your password"),
});

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Admin Login | USA Passport";
  }, []);

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      dispatch(authenticate(values))
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            toast.success(`${res?.message}`, {
              className: "toast-message",
            });
            navigate("/admin");
          } else if (res?.message) {
            toast.error(`${res?.message}`, {
              className: "toast-message",
            });
          }
        });
    },
  });
  return (
    <StyledLogin>
      <LoginContainer>
        <LoginLogo>
          <img src={AdminLogo} alt="adminLogo" />
        </LoginLogo>
        <FormContainer>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Email ID</label>
              <input
                type="email"
                name="email"
                placeholder="Your Email Address"
                className="form-control"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p>{errors.email}</p>
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                name="password"
                placeholder="Password"
                className="form-control"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p>{errors.password}</p>
            </div>
            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input type="checkbox" name="rememberme" />
                  <span></span>Remember me
                </label>
              </div>
            </div>
            <div className="form-btn">
              <button type="submit" className="btn blue-btn">
                Sign In{" "}
                <span>
                  <img src={RightArrow} alt="rightArrow" />
                </span>
              </button>
              <br />
              <Link to="#" className="forget-link">
                Forgot Password?
              </Link>
              <br />
              <br />
              <p className="error fnt16px"></p>
            </div>
          </form>
        </FormContainer>
      </LoginContainer>
    </StyledLogin>
  );
};

export default Login;
