import React from "react";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "../../../pages/PageNotFound/index";
import PrintOrder from "../../../Components/PrintOrder/index";

const Main = (props) => {
  return (
    <main>
      <Routes>
        <Route path="/:orderId" element={<PrintOrder />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </main>
  );
};

export default Main;
