import styled from "styled-components";

export const SearchField = styled.div`
  width: 50%;
  // float: right;
  display: flex;
  justify-content: flex-end;

  .search-bar {
    width: 260px;
    font-weight: 400;
  }
  .search-bar {
    border: 1px solid #e1e1e1;
    box-shadow: none;
    max-width: 260px;
    height: 40px;
    display: inline-block;
  }
  .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
      0 0 8px rgb(102 175 233 / 60%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  }

  @media only screen and (max-width: 730px) {
    justify-content: flex-start;
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  width: 71%;
  margin-bottom: 30px;
  .blue-btn {
    margin-top: 5px;
    margin-right: 5px;
  }

  .blue-btn {
    background-color: #18f;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 30px;
    display: inline-block;
    text-decoration: none;
    border-radius: 5px;
    border: none;

    &:focus,
    &:hover {
      background-color: #0b3157;
      color: #fff;
      -webkit-text-decoration: none;
      text-decoration: none;
    }

    & img {
      margin-left: 10px;
      vertical-align: middle;
      display: inline-block;
    }

    @media only screen and (max-width: 1120px) {
      font-size: 16px;
      padding: 12px 10px;
    }

    @media only screen and (max-width: 991px) {
      font-size: 15px;
      padding: 12px 10px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 12px;
      padding: 10px 15px;
    }
  }
`;

export const StyledDataTable = styled.div.attrs({ className: "styledTable" })`
  // & input {
  //   width: 25px;
  //   height: 25px;
  //   border: 1px solid #848484;
  //   border-radius: 5px;
  //   display: inline-block;
  //   vertical-align: middle;
  //   margin-right: 15px;
  //   position: relative;
  //   top: -1px;
  // }
  input:disabled {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
  }
  .uspassport-custom-tbl {
    overflow-x: unset;
    overflow-y: unset;
  }
  .rdt_Pagination {
    border-top: none;
  }
  .jrvHCp {
    display: flex;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    background-color: #ffffff;
    min-height: 80px !important;
  }
  .dptOXT {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    background-color: rgb(255, 255, 255);
    min-height: 80px;
  }

  .rdt_TableCol {
    font-weight: bold;
  }

  & div:first-child {
    white-space: pre-line;
    text-overflow: ellipsis;
    min-width: fit-content;
    max-height: fit-content;
  }

  .emmFZe div:first-child {
    white-space: pre-line;
    text-overflow: ellipsis;
    max-height: fit-content;
  }
  .table-block table.dataTable td,
  .table-block table.dataTable th {
    font-size: 0.875em;
    padding: 8px 10px;
  }

  .dMpKVF {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    background-color: rgb(255, 255, 255);
    min-height: 80px !important;
  }

  .fkWryv {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    background-color: #ffffff;
    min-height: 80px;
  }

  .caAlCG {
    flex-grow: 1;
    flex-shrink: 0;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
    font-size: 0.875em;
    padding: 10px 30px;
    /* min-width: 100px; */
  }

  .table-block table td {
    border-top: 1px solid #e8f5ff;
    font-size: 16px;
    padding: 10px;
    vertical-align: top;
  }
  .table-block table tr:nth-child(even) {
    background: #ccc;
  }

  .table-block table tr:nth-child(odd) {
    background: #fff;
  }

  & td {
    border-top: 1px solid #e8f5ff;
    font-size: 16px;
    padding: 5px;
    vertical-align: top;
  }
  & tr {
    padding: 10rem;
  }

  @media (min-width: 576px) .col-sm-6 {
    flex: 0 0 auto;
    /* width: 50%; */
  }
`;

export const StyledParentDiv = styled.div`
  display: flex;

  @media only screen and (max-width: 730px) {
    display: block;
  }
`;

export const StyledSearch = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled.div`
  // margin-bottom: 30px;s
  width: 50%;
`;
