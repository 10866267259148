import AdminNavbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";
import Main from "./Main";
import { StyledDashboard, StyledMain } from "./style";

const AdminLayout = (props) => (
  <StyledDashboard>
    <AdminNavbar {...props} />
    <AdminSidebar {...props} />
    <StyledMain>
      <Main {...props} />
    </StyledMain>
  </StyledDashboard>
);

export default AdminLayout;
