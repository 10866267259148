import React, { useEffect, useState } from "react";
import NewOrderIcon from "../../../../assests/images/icon1.svg";
import PendingOrderIcon from "../../../../assests/images/icon2.svg";
import DeletedIcon from "../../../../assests/images/icon3.svg";
import AwaitingIcon from "../../../../assests/images/icon4.svg";
import OrderHistoryIcon from "../../../../assests/images/icon5.svg";
import ContactCustomerIcon from "../../../../assests/images/icon6.svg";
import ManageTeamIcon from "../../../../assests/images/icon7.svg";
import GateWayIcon from "../../../../assests/images/icon8.svg";
import ChangePasswordIcon from "../../../../assests/images/icon10.svg";
import LogoutIcon from "../../../../assests/images/icon11.svg";
import CompletedOrderIcon from "../../../../assests/images/checklist.png";
import { StyledSidebar, StyledAdminMenu } from "./style";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/authSlice";
import {
  cleanOrdersList,
  getAllFolderCount,
  toggleSidebar,
} from "../../../../redux/orderSlice";
import { FaTimes } from "react-icons/fa";
import jwt from "jwt-decode";

const AdminSidebar = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("user");
  const count = useSelector((state) => state?.order?.count);
  const showSideBar = useSelector((state) => state?.order?.showSideBar);
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    dispatch(getAllFolderCount());
    dispatch(cleanOrdersList());
  }, [location?.pathname]);

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("user");
    dispatch(logout());
  };
  let userType;
  if (token !== null) {
    const decodedToken = jwt(token);
    userType = decodedToken?.user?.type;
  }

  return (
    <StyledSidebar>
      <div className={showSideBar ? "sidebar open" : "sidebar"}>
        <StyledAdminMenu>
          <button
            type="button"
            onClick={() => {
              dispatch(toggleSidebar());
            }}
            className="close-menu"
          >
            <FaTimes />
          </button>
          <ul>
            {userType !== "Telecaller" && (
              <>
                <li className={isActive === "recent" ? "active" : null}>
                  <NavLink
                    to="/admin/recent-order"
                    onClick={() => setIsActive("recent")}
                  >
                    <span className="side-icon">
                      <img src={NewOrderIcon} alt="NewOrderIcon" />
                    </span>
                    New Orders{" "}
                    <span className="count">{count?.newOrdersCount}</span>
                  </NavLink>
                </li>
                <li className={isActive === "pending" ? "active" : null}>
                  <NavLink
                    to="/admin/download-pending-order"
                    onClick={() => setIsActive("pending")}
                  >
                    <span className="side-icon">
                      <img src={PendingOrderIcon} alt="PendingOrderIcon" />
                    </span>
                    Pending Orders{" "}
                    <span className="count">{count?.pendingOrdersCount}</span>
                  </NavLink>
                </li>
                <li className={isActive === "completed" ? "active" : null}>
                  <NavLink
                    to="/admin/download-complete"
                    onClick={() => setIsActive("completed")}
                  >
                    <span className="side-icon">
                      <img src={CompletedOrderIcon} alt="CompletedOrderIcon" />
                    </span>
                    Completed Orders{" "}
                    <span className="count">{count?.completedOrdersCount}</span>
                  </NavLink>
                </li>
                <li className={isActive === "archive" ? "active" : null}>
                  {" "}
                  <NavLink
                    to="/admin/archive-order"
                    onClick={() => setIsActive("archive")}
                  >
                    <span className="side-icon">
                      <img src={DeletedIcon} alt="DeletedIcon" />
                    </span>
                    Deleted Orders{" "}
                    <span className="count">{count?.deletedOrdersCount}</span>
                  </NavLink>
                </li>
              </>
            )}
            <li className={isActive === "customer" ? "active" : null}>
              {" "}
              <NavLink
                to="/admin/customer-called"
                onClick={() => setIsActive("customer")}
              >
                <span className="side-icon">
                  <img src={AwaitingIcon} alt="AwaitingIcon" />
                </span>
                Customer Called{" "}
                <span className="count">{count?.contactCallOrdersCount}</span>
              </NavLink>
            </li>
            {userType !== "Telecaller" && (
              <li className={isActive === "allorder" ? "active" : null}>
                {" "}
                <NavLink
                  to="/admin/all-order"
                  onClick={() => setIsActive("allorder")}
                >
                  <span className="side-icon">
                    <img src={OrderHistoryIcon} alt="OrderHistoryIcon" />
                  </span>
                  Order History{" "}
                  <span className="count">{count?.allOrdersCount}</span>
                </NavLink>
              </li>
            )}
            <li className={isActive === "phone" ? "active" : null}>
              <NavLink
                to="/admin/phone-order"
                onClick={() => setIsActive("phone")}
              >
                <span className="side-icon">
                  <img src={ContactCustomerIcon} alt="ContactCustomerIcon" />
                </span>
                Contact Customer{" "}
                <span className="count">
                  {count?.contactCustomerOrdersCount}
                </span>
              </NavLink>
            </li>

            {userType === "Manager" && (

              <li className={isActive === "refund" ? "active" : null}>
                {" "}
                <NavLink
                  to="/admin/refund-order"
                  onClick={() => setIsActive("refund")}
                >
                  <span className="side-icon">
                    <img src={NewOrderIcon} alt="NewOrderIcon" />
                  </span>
                  Refunds{" "}
                  <span className="count">{count?.refundOrdersCount}</span>
                </NavLink>
              </li>
            )}

            {userType === "Admin" && userType !== "Telecaller" && (
              <>
                <li className={isActive === "twiliolog" ? "active" : null}>
                  {" "}
                  <NavLink
                    to="/admin/twilio-log"
                    onClick={() => setIsActive("twiliolog")}
                  >
                    <span className="side-icon">
                      <img src={OrderHistoryIcon} alt="OrderHistoryIcon" />
                    </span>
                    Twilio Log{" "}
                  </NavLink>
                </li>
                <li className={isActive === "processinglog" ? "active" : null}>
                  {" "}
                  <NavLink
                    to="/admin/processing-log"
                    onClick={() => setIsActive("processinglog")}
                  >
                    <span className="side-icon">
                      <img src={OrderHistoryIcon} alt="OrderHistoryIcon" />
                    </span>
                    Processing Log{" "}
                  </NavLink>
                </li>
                <li className={isActive === "documentlog" ? "active" : null}>
                  {" "}
                  <NavLink
                    to="/admin/document-log"
                    onClick={() => setIsActive("documentlog")}
                  >
                    <span className="side-icon">
                      <img src={OrderHistoryIcon} alt="OrderHistoryIcon" />
                    </span>
                    Document Log{" "}
                  </NavLink>
                </li>
                <li className={isActive === "stateTerritoryDetails" ? "active" : null}>
                  {" "}
                  <NavLink
                    to="/admin/state-details"
                    onClick={() => setIsActive("stateTerritoryDetails")}
                  >
                    <span className="side-icon">
                      <img src={OrderHistoryIcon} alt="OrderHistoryIcon" />
                    </span>
                    State Territory Details{" "}
                  </NavLink>
                </li>
                <li className={isActive === "save-continue" ? "active" : null}>
                  <NavLink
                    to="/admin/save-continue"
                    onClick={() => setIsActive("save-continue")}
                  >
                    <span className="side-icon">
                      <img src={NewOrderIcon} alt="NewOrderIcon" />
                    </span>
                    Save & Continue{" "}
                    <span className="count">
                      {count?.saveContinueOrdersCount}
                    </span>
                  </NavLink>
                </li>
                <li className={isActive === "refund" ? "active" : null}>
                  {" "}
                  <NavLink
                    to="/admin/refund-order"
                    onClick={() => setIsActive("refund")}
                  >
                    <span className="side-icon">
                      <img src={NewOrderIcon} alt="NewOrderIcon" />
                    </span>
                    Refunds{" "}
                    <span className="count">{count?.refundOrdersCount}</span>
                  </NavLink>
                </li>
                <li className={isActive === "manage-team" ? "active" : null}>
                  {" "}
                  <NavLink
                    to="/admin/manage-team"
                    onClick={() => setIsActive("manage-team")}
                  >
                    <span className="side-icon">
                      <img src={ManageTeamIcon} alt="ManageTeamIcon" />
                    </span>
                    Manage Team
                  </NavLink>
                </li>
                <li className={isActive === "setting" ? "active" : null}>
                  <NavLink
                    to="/admin/setting"
                    onClick={() => setIsActive("setting")}
                  >
                    <span className="side-icon">
                      <img src={GateWayIcon} alt="GateWayIcon" />
                    </span>
                    Gateway Setting
                  </NavLink>
                </li>
                <li
                  className={isActive === "change-password" ? "active" : null}
                >
                  <NavLink
                    to="/admin/change-password"
                    onClick={() => setIsActive("change-password")}
                  >
                    <span className="side-icon">
                      <img src={ChangePasswordIcon} alt="ChangePasswordIcon" />
                    </span>
                    Change Password{" "}
                  </NavLink>
                </li>
              </>
            )}
            <li>
              <NavLink to="/logout" onClick={handleLogout}>
                <span className="side-icon">
                  <img src={LogoutIcon} alt="LogoutIcon" />
                </span>
                Logout
              </NavLink>
            </li>
          </ul>
        </StyledAdminMenu>
      </div>
    </StyledSidebar>
  );
};

export default AdminSidebar;
