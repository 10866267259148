import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactDataTable from "../../../Components/ReactDataTable/ReactDataTable";
import { getProcessingLog } from "../../../redux/orderSlice";
import { StyledContainer, StyledOrderBlock, StyledPageTitle } from "./style";

const ProcessingLog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ProcessingLogList = useSelector(
    (state) => state?.order?.processingLogList
  );
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(
      getProcessingLog({
        start: page,
        end: limit,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          toast.success(`${res?.message}`, {
            className: "toast-message",
          });
        } else {
          toast.error(`${res?.message}`, {
            className: "toast-message",
          });
        }
      });
    document.title = "Processing Log | USA Passport";
  }, [dispatch, navigate, page, limit]);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.Id,
      sortable: true,
    },
    {
      name: "Order Id",
      selector: (row) => row.orderId,
      sortable: true,
    },
    {
      name: "Team Member Name",
      // width: "15rem",
      selector: (row) => row.teamMemberName,
      sortable: true,
    },
    {
      name: "Processing Status",
      selector: (row) => row.processingStatus,
      sortable: true,
    },
    {
      name: "Customer Email",
      selector: (row) => row.customerEmail,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) =>
        row?.ProcessingDate ? moment(new Date(row?.ProcessingDate)).format("MM-DD-YYYY") : null,
      sortable: true,
    },
  ];

  const tableData = ProcessingLogList;

  return (
    <StyledContainer>
      <StyledPageTitle>
        <h1>Processing Log</h1>
      </StyledPageTitle>
      <form>
        <StyledOrderBlock>
          <div className="table-block table-responsive">
            <ReactDataTable
              customColumns={columns}
              data={tableData}
              setPage={setPage}
              setLimit={setLimit}
              orderName="processinglog"
              showCustomSearchInput={true}
              buttonWrapper={null}
              topButtonWrapper={null}
            />
          </div>
        </StyledOrderBlock>
      </form>
    </StyledContainer>
  );
};

export default ProcessingLog;
