import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactDataTable from "../ReactDataTable/ReactDataTable";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";
import { viewProcessOrder } from "../../redux/orderSlice";

const ViewProcessOrder = () => {
  const dispatch = useDispatch();

  const { orderId } = useParams();

  const ProcessOrdersList = useSelector(
    (state) => state?.order?.processOrdersList
  );

  useEffect(() => {
    if (orderId) {
      dispatch(viewProcessOrder(orderId));
    }

    document.title = "Process Orders | USA Passport";
  }, [dispatch, orderId]);

  const tableData = ProcessOrdersList;

  const columns = [
    {
      name: "Order Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Assign To",
      selector: (row) => row.assignto,
      sortable: true,
    },
  ];

  return (
    <StyledContainer>
      <StyledPageTitle>
        <h1>Process orders</h1>
      </StyledPageTitle>
      <form>
        <StyledOrderBlock>
          <div className="table-block table-responsive">
            <ReactDataTable
              customColumns={columns}
              customData={tableData}
              buttonWrapper={""}
              hideSelectableRows={true}
            />
          </div>
        </StyledOrderBlock>
      </form>
    </StyledContainer>
  );
};

export default ViewProcessOrder;
