import React from "react";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "../../../pages/PageNotFound";
import ViewOrders from "../../../Components/ViewOrders/index";
import NewOrders from "../../../pages/Admin/NewOrders/index";
import PendingOrders from "../../../pages/Admin/PendingOrders/index";
import CompletedOrders from "../../../pages/Admin/CompletedOrders/index";
import ContactCustomer from "../../../pages/Admin/ContactCustomer/index";
import AwaitingResponse from "../../../pages/Admin/AwaitingResponse/index";
import OrderHistory from "../../../pages/Admin/OrderHistory/index";
import DeletedOrders from "../../../pages/Admin/DeletedOrders/index";
import ChangePassword from "../../../pages/Admin/ChangePassword/index";
import Gateway from "../../../pages/Admin/GateWay/index";
import ManageTeam from "../../../pages/Admin/ManageTeam/index";
import Login from "../../../pages/AdminLogin/Login";
import Refunds from "../../../pages/Admin/Refunds/Refunds";
import SaveAndContinue from "../../../pages/Admin/SaveAndContinue/SaveAndContinue";
import ViewProcessOrder from "../../../Components/ViewProcessOrder/ViewProcessOrder";
import FoundOrder from "../../../pages/FoundOrder/FoundOrder";
import TwilioLog from "../../../pages/Admin/TwilioLog";
import ProcessingLog from "../../../pages/Admin/ProcessingLog/index";
import DocumentLog from "../../../pages/Admin/DocumentLog/index";
import CustomerCalled from '../../../pages/Admin/CustomerCalled/index';
import StateTerritoryOrder from "../../../pages/Admin/StateTerritoryOrder";

const Main = (props) => {
  return (
    <main>
      <Routes>
        <Route path="/admin" element={<Login />} />
        <Route path="/" element={<NewOrders />} />
        <Route path="/view-order/:orderId" element={<ViewOrders />} />
        <Route path="/recent-order" element={<NewOrders />} />
        <Route path="/state-details" element={<StateTerritoryOrder />} />
        <Route path="/download-pending-order" element={<PendingOrders />} />
        <Route path="/download-complete" element={<CompletedOrders />} />
        <Route path="/phone-order" element={<ContactCustomer />} />
        <Route path="/awaiting-order" element={<AwaitingResponse />} />
        <Route path="/customer-called" element={<CustomerCalled />} />
        <Route path="/all-order" element={<OrderHistory />} />
        <Route path="/save-continue" element={<SaveAndContinue />} />
        <Route path="/archive-order" element={<DeletedOrders />} />
        <Route path="/refund-order" element={<Refunds />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/setting" element={<Gateway />} />
        <Route path="/manage-team" element={<ManageTeam />} />
        <Route path="/twilio-log" element={<TwilioLog />} />
        <Route path="/processing-log" element={<ProcessingLog />} />
        <Route path="/document-log" element={<DocumentLog />} />
        <Route path="/manage-team/:teamMemberId" element={<ManageTeam />} />
        <Route
          path="/view-process-order/:orderId"
          element={<ViewProcessOrder />}
        />
        <Route path="/found-order" element={<FoundOrder />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </main>
  );
};

export default Main;
