import React, { useEffect } from "react";
import AdminLogo from "../../../assests/images/logo.svg";
import { Link } from "react-router-dom";
import { EmailContainer } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { encryptOpenSSL } from "../../../redux/orderSlice";

const EmailContent = () => {
  const dispatch = useDispatch();
  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  const encryptedId = useSelector((state) => state?.order?.encryptedId);

  useEffect(() => {
    dispatch(
      encryptOpenSSL({ orderId: `${OrderDetails?.pre_id}${OrderDetails?.id}` })
    );
  }, []);

  return (
    <EmailContainer>
      <div>
        <img
          src={AdminLogo}
          alt="AdminLogo"
          styel={{ padding: " -37px", width: "130px" }}
        />
      </div>
      <hr />
      <p>
        <b>{`Dear ${OrderDetails?.firstName} ${OrderDetails?.lastName}`}</b>
      </p>
      <p>Thank you for using our services.</p>
      <p>Your documents are now ready to be downloaded</p>

      <h6>
        Username :
        <a href={`mailto:${OrderDetails?.email}`}>{OrderDetails?.email}</a>
      </h6>
      <p className="flex-class">
        <b>Order ID : </b>
        {OrderDetails?.pre_id}
        {OrderDetails?.id}
      </p>
      <p>Please click on the link below</p>

      <Link
        className="upload-doc-link"
        target="_blank"
        to={`https://usa-passport.com/found_order.php?order_id=${encryptedId}`}
      >
        Click here
      </Link>
      <br />
      <br />
      <ol>
        <b>Next Steps!</b>
        <br />
        <li>
          {" "}
          Print the document, attach the pictures, and attach all the required
          documentation.
          <p>
            <b>- For a passport renewal:</b> Form DS-82 form along with your
            most recent passport, name change document (if applicable), a recent
            photo, and fees (check or money order).
          </p>
          <p>
            <b>- New application: </b>
            Form DS-11. Proof of U.S. citizenship (such as a birth or
            naturalization certificate) Government-issued photo ID (such as a
            driver's license or military ID)
            <p> Color passport photo.</p>
            <p> Fee payment (check or money order).</p>
          </p>
        </li>

        <li>
          After clicking on the link you will be redirected to our portal, where
          you can download your documents. You will need your email address and
          Order ID.
        </li>
        <br />
        <li>
          Check that your that your application is correct and all relevant
          documents are included before submitting to your nearest passport
          office.
        </li>
        <br />

        <li>
          The cost of the passport must also be submitted to the relevant
          department at the nearest passport office. Please click on the
          following link to find the one closest to you
          <Link target="_blank" to="https://iafdb.travel.state.gov/">
            {" "}
            click here
          </Link>
        </li>
      </ol>
      <br />

      <p>
        <b>Important notice:</b>
      </p>

      <p>
        If you are not satisfied with the service you have received or wish to
        file a complaint, please contact us at
        <a href="mailto:inquiries@usa-passport.com">
          {" "}
          inquiries@usa-passport.com
        </a>
        , where we will be happy to assist you or alternatively click on one of
        the links below for more information.
      </p>

      <p>
        You can access the terms and conditions you agreed to by clicking here
        <Link target="_blank" to="https://usa-passport.com/terms.pdf">
          {" "}
          https://usa-passport.com/terms.pdf
        </Link>
      </p>

      <p>
        For further assistance, please contact our customer support at
        <a href="mailto:inquiries@usa-passport.com">
          {" "}
          inquiries@usa-passport.com
        </a>
        <p>
          Or visit{" "}
          <Link target="_blank" to="https://usa-passport.com/">
            https://usa-passport.com/
          </Link>{" "}
          and use our live support feature.
        </p>
      </p>

      <p className="flex-class">Customer Support Number: +1 (650) 399-9511</p>

      <p>Thank you for using our services</p>
      <p> Regards,</p>
      <br />
      <p>Support Team</p>
    </EmailContainer>
  );
};

export default EmailContent;
