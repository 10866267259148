import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { printOrderInfo } from "../../redux/orderSlice";
import { toast } from "react-toastify";
import moment from "moment";
import { StyledPrintOrder } from "./style";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import { useReactToPrint } from "react-to-print";
import { fileExists } from "../../utility/utility";

const PrintOrder = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const OrderInfo = useSelector((state) => state?.order?.orderInfo);

  const checkFileExist = (url) => {
    if (typeof url !== "undefined" && url !== null && url !== "") {
      return fileExists(url).then((response) => {
        return response;
      });
    }
  };

  useEffect(() => {
    if (orderId) {
      dispatch(printOrderInfo({ orderId: orderId }))
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
            handlePrint();
          }
        });
    }
    document.title = "Print Orders | USA Passport";
  }, [orderId, dispatch]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <StyledPrintOrder ref={componentRef}>
      <MDBAccordion initialActive={1}>
        <MDBAccordionItem
          collapseId={1}
          headerTitle="Order Details"
          // className="fa-plus:before"
          className="card-header"
        >
          <div id="pcoded" className="pcoded">
            <div className="pcoded-container navbar-wrapper">
              <div className="pcoded-main-container">
                <div className="pcoded-wrapper">
                  <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                      <div className="main-body">
                        <div className="page-wrapper">
                          <div className="page-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="card table-card">
                                  <div className="card-block">
                                    <div
                                      className="table-responsive"
                                      style={{ padding: "10px" }}
                                    >
                                      <table className="table table-bordered table-stripes">
                                        <tbody>
                                          {OrderInfo?.id && (
                                            <tr>
                                              <td>Order ID:</td>
                                              <td>{`${
                                                OrderInfo?.pre_id
                                                  ? OrderInfo?.pre_id
                                                  : ""
                                              }${OrderInfo?.id}`}</td>
                                            </tr>
                                          )}
                                          {OrderInfo?.id && (
                                            <tr>
                                              <td>
                                                I have read the Privacy Policy
                                                and accept the Terms of
                                                Business.
                                              </td>
                                              <td></td>
                                            </tr>
                                          )}
                                          {OrderInfo?.application_type && (
                                            <tr>
                                              <td>Application type.</td>
                                              <td>
                                                {OrderInfo?.application_type}
                                              </td>
                                            </tr>
                                          )}
                                          <tr>
                                            <td colSpan="2">
                                              <b>About the Applicant</b>
                                            </td>
                                          </tr>
                                          {OrderInfo?.firstName && (
                                            <tr>
                                              <td>First Name</td>
                                              <td>{OrderInfo?.firstName}</td>
                                            </tr>
                                          )}
                                          {OrderInfo?.lastName && (
                                            <tr>
                                              <td>Last Name</td>
                                              <td>{OrderInfo?.lastName}</td>
                                            </tr>
                                          )}
                                          {OrderInfo?.dob && (
                                            <tr>
                                              <td>Date Of Birth</td>
                                              <td>
                                                {OrderInfo?.dob
                                                  ? moment(
                                                      OrderInfo?.dob
                                                    ).format("MM-DD-YYYY")
                                                  : ""}
                                              </td>
                                            </tr>
                                          )}
                                          {OrderInfo?.CountryList && (
                                            <tr>
                                              <td>Country Of Birth</td>
                                              <td>{OrderInfo?.CountryList}</td>
                                            </tr>
                                          )}
                                          {OrderInfo?.ssn && (
                                            <tr>
                                              <td>Social Security Number</td>
                                              <td>{OrderInfo?.ssn}</td>
                                            </tr>
                                          )}
                                          {OrderInfo?.heightFootList && (
                                            <tr>
                                              <td>Height</td>
                                              <td>{`${OrderInfo?.heightFootList}:${OrderInfo?.heightInchList}`}</td>
                                            </tr>
                                          )}
                                          {OrderInfo?.eyeList && (
                                            <tr>
                                              <td>Eye Color</td>
                                              <td>{OrderInfo?.eyeList}</td>
                                            </tr>
                                          )}
                                          {OrderInfo?.employer && (
                                            <tr>
                                              <td>Employer or School</td>
                                              <td>{OrderInfo?.employer}</td>
                                            </tr>
                                          )}
                                          <tr>
                                            <td colSpan="2">
                                              <b>Contact Information</b>
                                            </td>
                                          </tr>
                                          {OrderInfo?.mailStreet && (
                                            <tr>
                                              <td>
                                                Street Address RFD#, P.O. Box,
                                                or URB:{" "}
                                              </td>
                                              <td>{OrderInfo?.mailStreet}</td>
                                            </tr>
                                          )}
                                          {OrderInfo?.mailStreet2 && (
                                            <tr>
                                              <td>Street Address 2</td>
                                              <td>{OrderInfo?.mailStreet2}</td>
                                            </tr>
                                          )}
                                          {OrderInfo?.mailCity && (
                                            <tr>
                                              <td>City</td>
                                              <td>{OrderInfo?.mailCity}</td>
                                            </tr>
                                          )}
                                          {OrderInfo?.mailCountryList && (
                                            <tr>
                                              <td>Country</td>
                                              <td>
                                                {OrderInfo?.mailCountryList}
                                              </td>
                                            </tr>
                                          )}
                                          {OrderInfo?.mailStateList && (
                                            <tr>
                                              <td>State</td>
                                              <td>
                                                {OrderInfo?.mailStateList}
                                              </td>
                                            </tr>
                                          )}
                                          {OrderInfo?.mailZip && (
                                            <tr>
                                              <td>Zip Code</td>
                                              <td>{OrderInfo?.mailZip}</td>
                                            </tr>
                                          )}
                                          {OrderInfo?.mailCareOf && (
                                            <tr>
                                              <td>
                                                In Care Of (e.g. In Care Of -
                                                Jane Doe){" "}
                                              </td>
                                              <td>{OrderInfo?.mailCareOf}</td>
                                            </tr>
                                          )}
                                          {OrderInfo?.permanentAddressList && (
                                            <tr>
                                              <td>
                                                Is This Your Permanent Address{" "}
                                              </td>
                                              <td>
                                                {
                                                  OrderInfo?.permanentAddressList
                                                }
                                              </td>
                                            </tr>
                                          )}
                                          {OrderInfo?.email && (
                                            <tr>
                                              <td>Your Email Address </td>
                                              <td>{OrderInfo?.email}</td>
                                            </tr>
                                          )}
                                          {OrderInfo?.phoneNumber3 && (
                                            <tr>
                                              <td> Phone Number</td>
                                              <td>{OrderInfo?.phoneNumber3}</td>
                                            </tr>
                                          )}
                                          <tr>
                                            <td colSpan="2">
                                              <b>Travel Plans</b>
                                            </td>
                                          </tr>
                                          {OrderInfo?.TripDate && (
                                            <tr>
                                              <td> Date Of Your Trip </td>
                                              <td>
                                                {moment(
                                                  OrderInfo?.TripDate
                                                ).format("MM-DD-YYYY")}
                                              </td>
                                            </tr>
                                          )}
                                          {OrderInfo?.TripLength && (
                                            <tr>
                                              <td> Duration Of Trip </td>
                                              <td>
                                                {moment(
                                                  OrderInfo?.TripLength
                                                ).format("MM-DD-YYYY")}
                                              </td>
                                            </tr>
                                          )}
                                          {OrderInfo?.Countries_visit && (
                                            <tr>
                                              <td> Countries To Be Visited </td>
                                              <td>
                                                {OrderInfo?.Countries_visit}
                                              </td>
                                            </tr>
                                          )}
                                          <tr>
                                            <td colSpan="2">
                                              <b>Emergency Contact</b>
                                            </td>
                                          </tr>
                                          {OrderInfo?.emergency_ContactName && (
                                            <tr>
                                              <td> First & Last Name </td>
                                              <td>
                                                {
                                                  OrderInfo?.emergency_ContactName
                                                }
                                              </td>
                                            </tr>
                                          )}
                                          {OrderInfo?.emergency_ContactsAddress && (
                                            <tr>
                                              <td>
                                                {" "}
                                                Street Address / RFD# Or P.O.
                                                Box{" "}
                                              </td>
                                              <td>
                                                {
                                                  OrderInfo?.emergency_ContactsAddress
                                                }
                                              </td>
                                            </tr>
                                          )}
                                          {OrderInfo?.emergency_ContactApartment && (
                                            <tr>
                                              <td>Apt/Suite# </td>
                                              <td>
                                                {
                                                  OrderInfo?.emergency_ContactApartment
                                                }
                                              </td>
                                            </tr>
                                          )}
                                          {OrderInfo?.emergency_ContactsCity && (
                                            <tr>
                                              <td>City</td>
                                              <td>
                                                {
                                                  OrderInfo?.emergency_ContactsCity
                                                }
                                              </td>
                                            </tr>
                                          )}
                                          {OrderInfo?.emergency_ContactsState && (
                                            <tr>
                                              <td>State</td>
                                              <td>
                                                {
                                                  OrderInfo?.emergency_ContactsState
                                                }
                                              </td>
                                            </tr>
                                          )}
                                          {OrderInfo?.emergency_ZipCode && (
                                            <tr>
                                              <td>Zip Code</td>
                                              <td>
                                                {OrderInfo?.emergency_ZipCode}
                                              </td>
                                            </tr>
                                          )}
                                          {OrderInfo?.emergency_Phone && (
                                            <tr>
                                              <td>Telephone Number </td>
                                              <td>
                                                {OrderInfo?.emergency_Phone}
                                              </td>
                                            </tr>
                                          )}
                                          {OrderInfo?.emergency_Relationship && (
                                            <tr>
                                              <td>Relationship </td>
                                              <td>
                                                {
                                                  OrderInfo?.emergency_Relationship
                                                }
                                              </td>
                                            </tr>
                                          )}
                                          <tr>
                                            <td colSpan="2">
                                              <b>Most Recent Passport</b>
                                            </td>
                                          </tr>
                                          {OrderInfo?.mostrecent_passportbook && (
                                            <tr>
                                              <td>Recent Passport </td>
                                              <td>
                                                {
                                                  OrderInfo?.mostrecent_passportbook
                                                }
                                              </td>
                                            </tr>
                                          )}
                                          <tr>
                                            <td colSpan="2">
                                              <b>Passport Book</b>
                                            </td>
                                          </tr>
                                          {OrderInfo?.passport_Book && (
                                            <tr>
                                              <td>
                                                Do you still have the book in
                                                your possession{" "}
                                              </td>
                                              <td>
                                                {" "}
                                                {OrderInfo?.passport_Book}
                                              </td>
                                            </tr>
                                          )}
                                          {OrderInfo?.BookIssueDate && (
                                            <tr>
                                              <td>
                                                The date your most recent
                                                passport book was issued{" "}
                                              </td>
                                              <td>
                                                {" "}
                                                {moment(
                                                  OrderInfo?.BookIssueDate
                                                ).format("MM-DD-YYYY")}
                                              </td>
                                            </tr>
                                          )}
                                          {OrderInfo?.firstNameOnBook && (
                                            <tr>
                                              <td>First and Middle Name </td>
                                              <td>
                                                {OrderInfo?.firstNameOnBook}
                                              </td>
                                            </tr>
                                          )}
                                          {OrderInfo?.lastNameOnBook && (
                                            <tr>
                                              <td>Last Name </td>
                                              <td>
                                                {" "}
                                                {OrderInfo?.lastNameOnBook}
                                              </td>
                                            </tr>
                                          )}
                                          {OrderInfo?.BookNumber && (
                                            <tr>
                                              <td>Book number </td>
                                              <td>{OrderInfo?.BookNumber}</td>
                                            </tr>
                                          )}
                                          <tr>
                                            <td colSpan="2">
                                              <b>Your Most Recent Passport</b>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              Has your name changed since your
                                              most recent document was issued
                                            </td>
                                            <td></td>
                                          </tr>
                                          <tr>
                                            <td>Reason for the name change</td>
                                            <td>
                                              {OrderInfo?.NameChangeReason}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Date of name change</td>
                                            <td>
                                              {OrderInfo?.NameChangeDate
                                                ? moment(
                                                    OrderInfo?.NameChangeDate
                                                  ).format("MM-DD-YYYY")
                                                : ""}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Place of name change</td>
                                            <td>
                                              {OrderInfo?.NameChangePlace}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              Has your name changed since your
                                              most recent document was issued
                                            </td>
                                            <td></td>
                                          </tr>
                                          <tr>
                                            <td colSpan="2">
                                              <b>
                                                Are you known by other names
                                              </b>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Other names</td>
                                            <td>
                                              {OrderInfo?.OtherFirstname_1}{" "}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <b>Type of Passport Ordered:</b>
                                            </td>
                                            <td>{OrderInfo?.ppptypex}</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <b>US Date:</b>
                                            </td>
                                            <td>
                                              {OrderInfo?.us_date
                                                ? moment(
                                                    OrderInfo?.us_date
                                                  ).format("MM-DD-YYYY")
                                                : ""}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <b>US Time:</b>
                                            </td>
                                            <td>{OrderInfo?.us_time}</td>
                                          </tr>
                                          <tr>
                                            <td colSpan="2">
                                              <b>View Digital Signature:</b>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colSpan="2">
                                              <img
                                                src={
                                                  checkFileExist(
                                                    `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}${OrderInfo?.d_sign}`
                                                  )
                                                    ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}${OrderInfo?.d_sign}`
                                                    : `https://usa-passport.com/${OrderInfo?.d_sign}`
                                                }
                                                alt="img"
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MDBAccordionItem>
      </MDBAccordion>
    </StyledPrintOrder>
  );
};

export default PrintOrder;
