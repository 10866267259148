import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import AdminLayout from "./containers/Layout/AdminLayout/AdminLayout";
import SignupLayout from "./containers/Layout/SignupLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminPrintLayout from "./containers/Layout/AdminPrintLayout/AdminPrintLayout";
// import DefaultLayout from "./containers/Layout/DefaultLayout/DefaultLayout";

const App = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route path="/admin/print-order/*" element={<AdminPrintLayout />} />
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="/" element={<SignupLayout />} />
      </Routes>
    </>
  );
};

export default App;
