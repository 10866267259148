import styled from "styled-components";

export const StyledPrintOrder = styled.div`
  .pcoded-main-container {
    display: block;
    position: relative;
    background: #f6f7fb;
    min-height: calc(100vh - 56px);
  }
  .pcoded-inner-content {
    padding: 10px;
  }

  .main-body {
    background-color: #f3f3f3;
    font-size: 0.875em;
    overflow-x: hidden;
    color: #353c4e;
    font-family: open sans, sans-serif;
    background-attachment: fixed;
  }

  .main-body .page-wrapper {
    padding: 1.8rem;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s;
  }
  card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
    box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
    border: none;
    margin-bottom: 30px;
  }
  .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }
  .card .card-header {
    background-color: #404e67;
    padding: 15px 20px;
    color: #fff;
  }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  .card .card-header .card-header-right {
    border-radius: 0 0 0 7px;
    right: 10px;
    top: 18px;
    display: inline-block;
    float: right;
    padding: 7px 0;
    position: absolute;
  }
  .card .card-header .card-header-right .card-option {
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
  }
  .feather {
    font-family: feather !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .card .card-header + .card-block,
  .card .card-header + .card-block-big {
    padding-top: 0;
  }
  .table-card .card-block {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
  .card-block {
    padding: 1.25rem;
  }
  .table-responsive {
    display: inline-block;
    width: 100%;
    overflow-x: auto;
  }
  .table-bordered {
    border: 1px solid #e9ecef;
  }
  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
  }
  table {
    border-collapse: collapse;
  }
  .card .card-block table tr {
    padding-bottom: 20px;
  }
  .table-card .card-block .table tr td:last-child,
  .table-card .card-block .table tr th:last-child {
    padding-right: 20px;
  }
  .table-card .card-block .table tr td:first-child,
  .table-card .card-block .table tr th:first-child {
    padding-left: 20px;
  }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #e9ecef;
  }
  .table td,
  .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e9ecef;
  }

  .accordion-button:not(.collapsed) {
    background-color: #404e67;
    padding: 20px 20px;
    color: #fff;
    margin-bottom: 0;
    margin-top: 20px;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
      var(--bs-accordion-border-color);
  }

  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
    border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
    background-color: #404e67;
    color: #fff;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: content: "\e8b1";
    transform: var(--bs-accordion-btn-icon-transform);
}
  .accordion-body {
    padding: 0;
  }
 

  .accordion-button:focus {
    z-index: 3;
    border-color: #404e67;
    outline: 0;
    box-shadow: none;
  }
  th,
  td {
    white-space: nowrap;
  }
  
`;
