import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import orderReducer from "./orderSlice";
import gateWayReducer from "./gateWaySlice";
import manageTeamReducer from "./manageTeamSlice";
import remarkReducer from "./remarkSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    order: orderReducer,
    manageTeam: manageTeamReducer,
    gateway: gateWayReducer,
    remark: remarkReducer,
  },
});
