import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLogo from "../../../assests/images/logo.svg";
import OrderConfirmation from "../../../assests/img/orderconfirmation.png";
import PaymentConfirmation from "../../../assests/img/paymentconfirmation.png";
import CompletedOrder from "../../../assests/img/completeorder.png";
import { DefenceContainer, StyledOrderBlock } from "./style";
import { Link } from "react-router-dom";
import moment from "moment";
import rightArrow from "../../../assests/images/right-arrow.png";
import { GetDefencePackPdf } from "../../../redux/orderSlice";
import { FaDownload } from "react-icons/fa";
import { fileExists } from "../../../utility/utility";
import { date } from "yup";

const DefenceContent = ({ defencePackName }) => {
  const dispatch = useDispatch();
  const OrderDetails = useSelector((state) => state?.order?.orderDetails);

  const handleDefencePack = (e) => {
    let data = {
      orderId: OrderDetails?.id,
      defencePackType: Number(e.target.id),
    };
    dispatch(GetDefencePackPdf(data))
      .unwrap()
      .then(async (res) => {
        if (res !== null) {
          const fileURL = URL.createObjectURL(res);
          window.open(fileURL);
        }
      });
  };

  const checkFileExist = (url) => {
    if (typeof url !== "undefined" && url !== null && url !== "") {
      return fileExists(url).then((response) => {
        return response;
      });
    }
  };
  const today = new date();

  return (
    <DefenceContainer>
      <div>
        <img src={AdminLogo} alt="AdminLogo" className="arrival-img" />
      </div>

      <h2>
        <strong>usa-passport.com</strong>
      </h2>
      <div className="header">
        Electric Doc Services LLC <br />
        1800 Pembrook Dr.
        <br />
        Suite 300
        <br />
        Orlando
        <br />
        FL, 32810
        <br />
        Tel: +1 (650) 3999511
        <br />
        inquiries@usa-passport.com
        <br />
        Company Number: 85-1546748
      </div>

      <div className="main">
        {moment(today).format("MMMM DD, YYYY")}
        <br />
        {OrderDetails?.transaction_id}
        <br />
        Dear {OrderDetails?.merchant_name}
        <br />
        <br />
        <br />
        <ol>
          We have provided the service we advertise and we have delivered the
          documents to the customer. Please find enclosed all the details
          regarding our service. This is the process a customer must to go
          through to place an order with us:
          <br />
          <br />
          <li>
            Once the customer has paid they receive an email confirmation
            explaining the next steps, again, at the bottom it explains if you
            are not satisfied with the service you have received or wish to file
            a complaint they should reply to that email or follow the link to
            our contact page. In this email there is also a link to a <b>PDF</b>{" "}
            copy of our terms and conditions that the customer had agreed to
            page
            <b> (Page 2, 3, and 4)</b>.<br />
            <br />
          </li>
          <li>
            We are then able to see
            <b>
              {" "}
              Order ID, Date & Time (EST), Device IP address, Device, Device
              Type, Browser, OS, and Device location,
            </b>{" "}
            also a <b>signature</b>. With all this, We can confirm the amount of
            times document was downloaded. ( Page 5 )
          </li>
        </ol>
        <p>
          We pass all customer information like{" "}
          <b>Device IP address, email, phone numbers </b>and <b>addresses </b>to
          Payees, if these were incorrect or suspected fraud these payments
          would be declined.
        </p>
        <br />
        <br />
        <div className="container-box">
          <h5>
            {" "}
            AT NO POINT HAS THIS CUSTOMER CONTACTED US FOR A REFUND OR TO
            COMPLAIN SO WE FEEL THIS FIRST CHARGEBACK SHOULD BE REJECTED.{" "}
          </h5>
        </div>
        <br /> <br />
        <p>
          <span>Payment Confirmation</span> (after completion)
        </p>
        <p>Payment confirmation page with various contact options.</p>
        <img src={PaymentConfirmation} alt="PaymentConfirmation" />
        <br /> <br />
        <p>
          <span>Order Confirmation</span>
        </p>
        <p>
          In the confirmation email that the customer receives once the order
          has been paid for, there is a link to our terms and conditions. At
          this point they are still able to contact us for any queries, concerns
          or for a refund. This customer has not contacted us, despite many
          opportunities to do so. A copy of our terms can be found in this link
          <Link to="#">https://usa-passport.com/tc.php</Link>
        </p>
        <img src={OrderConfirmation} alt="OrderConfirmation" />
        <br /> <br />
        <br />
        <p>
          <span>Completed Order</span>
        </p>
        <p>
          The customer is emailed a link to our<b> portal </b>to which then will
          allow them to download their completed document. This will allow them
          to apply for the passport at a passport acceptance facility or via
          mail if eligible. The document has been properly checked and completed
          by our staff on their behalf.
        </p>
        <img src={CompletedOrder} alt="CompletedOrder" />
        <br /> <br />
        <br />
        <span>Completed Order</span>
        <br />
        <p>
          We email the customer their prepared document in a <b>PDF </b>format,
          and are able to track how many times it was downloaded.
          <br />
          <br />
          <p>
            When the customer agrees to our terms and conditions, we can see a
            digital signature that the customer has entered. Sometimes the
            signature is not perfect depending on the device being used,
            however, we can verify that we have the
            <b>
              {" "}
              Order ID, Date & Time (EST), Device IP address, Device, Device
              Type, Browser, OS,
            </b>{" "}
            and <b> Device location.</b>
          </p>
        </p>
        <div className="download-history">
          {defencePackName !== "defencePack1" ? (
            <StyledOrderBlock>
              <h3>Download History</h3>
              <div className="table-block table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "3rem" }}>#</th>
                      <th style={{ width: "10rem" }}>Order ID</th>
                      <th style={{ width: "10rem" }}>Date & Time(EST)</th>
                      <th style={{ width: "10rem" }}>Device IP Address</th>
                      <th style={{ width: "8rem" }}>Device</th>
                      <th style={{ width: "10rem" }}>Device Type</th>
                      <th style={{ width: "8rem" }}>Browser</th>
                      <th style={{ width: "10rem" }}>OS</th>
                      <th style={{ width: "5rem" }}>Device Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderDetails?.downloadHistory ? (
                      OrderDetails?.downloadHistory?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{`${OrderDetails?.pre_id}${item?.order_id}`}</td>
                            <td>
                              {item?.create_ts
                                ? moment(item?.create_ts).format(
                                    "MM-DD-YYYY h:mm:ss"
                                  )
                                : ""}
                            </td>
                            <td>{item?.ip}</td>
                            {console.log("item", item)}
                            <td>{item?.browser.split("-")?.[2]}</td>
                            <td>
                              {`${item?.browser.split("-")?.[2]} ${
                                item?.browser.split("-")?.[3]
                              } ${item?.browser.split("-")?.[4]}`}
                            </td>
                            <td>{item?.browser.split("-")?.[0]}</td>
                            <td>{item?.os}</td>
                            <td>{item?.timezone}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="4" align="center">
                          No record found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          ) : (
            <div className="row">
              <div className="col-md-12">
                <StyledOrderBlock>
                  <h3>Email History</h3>
                  <table className="table-block table-responsive">
                    <thead>
                      <tr>
                        <th style={{ width: "10%" }}>#</th>
                        <th style={{ width: "25%" }}>Order ID</th>
                        <th style={{ width: "35%" }}>Download</th>
                        <th style={{ width: "40%" }}>Date & Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {OrderDetails?.mailHistory?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="font-normal">
                              {OrderDetails?.pre_id}
                              {item?.order_id}
                            </td>
                            {OrderDetails?.uploadDoc?.length ? (
                              <td>
                                <a
                                  href={
                                    OrderDetails?.length !== 0
                                      ? checkFileExist(
                                          `${process.env.REACT_APP_BASE_URL}/${
                                            process.env
                                              .REACT_APP_PUBLIC_UPLOAD_PATH
                                          }docs/${
                                            item?.uploadDoc == null
                                              ? OrderDetails?.uploadDoc[0]
                                                  ?.file1
                                              : item?.uploadDoc.file1
                                          }`
                                        )
                                        ? `${process.env.REACT_APP_BASE_URL}/${
                                            process.env
                                              .REACT_APP_PUBLIC_UPLOAD_PATH
                                          }docs/${
                                            item?.uploadDoc == null
                                              ? OrderDetails?.uploadDoc[0]
                                                  ?.file1
                                              : item?.uploadDoc.file1
                                          }`
                                        : `https://usa-passport.com/uploads/${
                                            item?.uploadDoc == null
                                              ? OrderDetails?.uploadDoc[0]
                                                  ?.file1
                                              : item?.uploadDoc.file1
                                          }`
                                      : null
                                  }
                                  className="blue-btn"
                                  download
                                  rel="noreferrer"
                                >
                                  Download File{" "}
                                  <span>
                                    <FaDownload className="download-icons" />
                                  </span>
                                </a>
                              </td>
                            ) : (
                              ""
                            )}
                            <td>
                              {moment(item?.create_ts).format(
                                "MM-DD-YYYY h:mm:ss"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </StyledOrderBlock>
              </div>
            </div>
          )}
        </div>
        <div className="sign-block">
          <img
            src={
              OrderDetails?.length !== 0
                ? checkFileExist(
                    `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}${OrderDetails?.d_sign}`
                  )
                  ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}${OrderDetails?.d_sign}`
                  : `https://usa-passport.com/${OrderDetails?.d_sign}`
                : null
            }
            alt="sign"
          />
        </div>
        <br />
        <p>
          {" "}
          <span> PDF below </span>are the documents issued and sent to the
          e-mail address the customer provided.
        </p>
        <br />
        <iframe
          width="200"
          height="150"
          title="pdf"
          src={
            OrderDetails?.length !== 0
              ? checkFileExist(
                  `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.uploadDoc[0]?.file1}}`
                )
                ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.uploadDoc[0]?.file1}`
                : `https://usa-passport.com/uploads/${OrderDetails?.uploadDoc[0]?.file1}`
              : null
          }
        />
        <br /> <br />
      </div>
      {defencePackName !== "defencePack1" ? (
        <button type="button" onClick={handleDefencePack} id="1">
          Print Defence Pack 1{" "}
          <span>
            <img src={rightArrow} alt="rightArrow" />
          </span>
        </button>
      ) : (
        <button type="button" onClick={handleDefencePack} id="2">
          Print Defence Pack 2{" "}
          <span>
            <img src={rightArrow} alt="rightArrow" />
          </span>
        </button>
      )}
    </DefenceContainer>
  );
};

export default DefenceContent;
