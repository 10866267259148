import React, { useState } from "react";
import { StyledManageBlock } from "../../pages/Admin/ManageTeam/style";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getTeamMemberList } from "../../redux/manageTeamSlice";

const FilterTeamMember = ({
  monthName,
  yearName,
  setMonthName,
  setYearName,
}) => {
  const dispatch = useDispatch();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let defaultInitialValues = {
    monthOrder: "",
    yearOrder: "",
  };
  const FilterteamMemberSchema = yup.object({
    monthOrder: yup.string().required("Please Select Month."),
    yearOrder: yup.string().required("Please Select Year."),
  });

  if (monthName !== null && yearName !== undefined && yearName !== undefined) {
    defaultInitialValues = {
      monthOrder: monthName ? monthName : "",
      yearOrder: yearName ? yearName : "",
    };
  }

  let [initialValues, setInitialValues] = useState(defaultInitialValues);

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: monthName !== null ? defaultInitialValues : initialValues,
    enableReinitialize: true,
    validationSchema: FilterteamMemberSchema,
    onSubmit: (values) => {
      const data = {
        monthName: values.monthOrder,
        year: values.yearOrder,
      };
      setMonthName(values.monthOrder);
      setYearName(values.yearOrder);
      dispatch(getTeamMemberList(data))
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
          } else {
            toast.error(`${res.message}`, {
              className: "toast-message",
            });
          }
        })
        .catch((err) => {
          toast.error(`${err.message}`, {
            className: "toast-message",
          });
        });
    },
  });

  return (
    <StyledManageBlock>
      <form onSubmit={handleSubmit}>
        <div className="col-md-2 col-sm-6">
          <div className="form-group">
            <select
              name="monthOrder"
              className="form-control"
              autoComplete="off"
              value={values.monthOrder}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Select Month</option>
              {months?.map((month, i) => {
                return (
                  <option value={month} key={i}>
                    {month}
                  </option>
                );
              })}
            </select>
            <p>{errors.monthOrder}</p>
          </div>
        </div>
        <div className="col-md-2 col-sm-6">
          <div className="form-group">
            <select
              type="number"
              name="yearOrder"
              id="yearOrder"
              tabIndex="19"
              className="form-control"
              autoComplete="off"
              value={values.yearOrder}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {" "}
              <option value="">Select Year</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
            </select>
            <p>{errors.yearOrder}</p>
          </div>
        </div>
        <div className="col-md-2 col-sm-4">
          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </form>
    </StyledManageBlock>
  );
};

export default FilterTeamMember;
