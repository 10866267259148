import styled from "styled-components";

export const EmailContainer = styled.div`
  padding: 10px;
  margin: 5px;
  border: solid 2px #000;

  & hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 2px solid black;
    opacity: unset;
  }
  & a {
    text-decoration: none;
    color: #337ab7;
  }

  & button {
    color: #fff;
    background: #0d6efd;
    border: none;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 20px;
  }

  .flex-class {
    display: flex;
  }

  & img,
  svg {
    vertical-align: middle;
    width: 10rem;
  }

  hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 2px solid black;
    /* opacity: .25; */
  }

  .upload-doc-link {
    color: #fff;
    text-decoration: none;
    background: #18f;
    border: none;
    border-radius: 20px;
    padding: 12px;
  }
`;
