import styled from "styled-components";

export const StyledContainer = styled.div`
  & p {
    color: red;
    font-weight: 500;
  }
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    display: initial;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
    display: inline-block;

    @media only screen and (max-width: 991px) {
      display: block;
      width: auto;
    }
  }
`;

export const StyledManageBlock = styled.div`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  overflow: hidden;
  padding: 0px 25px 25px 25px;
  margin-bottom: 30px;
  font-size: 18px;

  @media only screen and (max-width: 767px){
    padding: 0px 2px 15px 2px;
}

  & h2 {
    background: #0b3157;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 25px;
    margin: 0px -25px 25px;
  }

  // .pdl-0 {
  //   padding-left: 0px;
  // }

  // .pdr-0 {
  //   padding-right: 0px;
  // }
  
  
  & div:first-child {
    white-space: pre-line;
    // overflow: auto;
    text-overflow: ellipsis;
  }
  .table-block table {
    width: 100%;
    table-layout: fixed;
  }
  .table-block table tr:first-child td {
    border-top: 0px;
  }
  .table-block table td {
    border-top: 1px solid #e8f5ff;
    font-size: 16px;
    padding: 10px;
    vertical-align: top;


    @media only screen and (max-width: 991px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 767px){
    font-size: 12px;
    padding: 5px;
   }
  }
  .order-dtl-block table td {
    font-size: 18px;

    @media only screen and (max-width: 991px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 767px){
    font-size: 14px;
    padding: 5px;
   }
  }
  table td {
    font-size: 18px;
  }
  table td:nth-child(2) {
    font-weight: 600;
  }
  .form-control {
    border: 1px solid #e1e1e1;
    width:100%
    box-shadow: none;
    font-size: 16px;
    color: #111;
    height: 40px;
    background: #fff;
  }
  .file-upload {
    border: 1px solid #e1e1e1;
    box-shadow: none;
    font-size: 18px;
    color: #111;
    height: 40px;
    background: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0px 5px;
  }
  .file-upload input {
    width: 0px;
    height: 0px;
    position: absolute;
  }
  .file-upload span {
    background: #18f;
    color: #fff;
    border-radius: 2px;
    font-size: 14px;
    padding: 5px 10px;
    cursor: pointer;
  }
  textarea.form-control {
    min-height: 88px;
    height: auto;
    margin-bottom: 15px;
  }
  .checkbox label {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .blue-btn {
    margin-top: 5px;
  }

  & p {
    color: red;
  }

  .blue-btn {
    background-color: #18f;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 30px;
    display: inline-block;
    border: none;
    border-radius: 4px;

    &:focus,
    &:hover {
      background-color: #0b3157;
      color: #fff;
      -webkit-text-decoration: none;
      text-decoration: none;
    }
    @media only screen and (max-width: 991px) {
      font-size: 15px;
      padding: 12px 10px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 15px;
      padding: 10px 15px;
    }
  }

  & form {
    display: flex;
    gap: 20px;

    @media only screen and (max-width: 767px) {
      display: block;
    }
  }

  & hr {
    width: 100%;
    size: 10px;
  }

  @media only screen and (max-width: 767px) {
    .col-md-6.pdl-0 {
      padding-left: 15px;
    }
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-control {
    border: 1px solid #e1e1e1;
    box-shadow: none;
    font-size: 16px;
    color: #111;
    height: 40px;
    background: #fff;

    @media only screen and (max-width: 767px){
    font-size: 14px;
  }
  }
  .btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
  }
  .btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }
`;
