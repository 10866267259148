import React, { useState } from "react";
// import { StyledOrderBlock } from "./style";
import { StyledOrderBlock } from "../style";
import { addNewRemark } from "../../../redux/remarkSlice";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  ChangeOrderStatus,
  getAllFolderCount,
  getOrderDetailsByOrderId,
  getOrdersList,
} from "../../../redux/orderSlice";

const initialValues = {
  remark: "",
  whatsapp: "",
  telephone: "",
  status_refund: "",
  voided: "",
  chargeback: "",
};

const remarkSchema = yup.object({
  remark: yup.string().required("Please enter your remark"),
  whatsapp: "",
  telephone: "",
  status_refund: "",
  voided: "",
  chargeback: "",
});

const AddComment = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  const [btnClicked, setBtnClicked] = React.useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: remarkSchema,
    onSubmit: (values) => {
      let remarkDataArr;
      if (btnClicked === "saveComment") {
        remarkDataArr = {
          orderId: OrderDetails?.id,
          remark: values.remark,
          whatsApp: values.whatsapp,
          telePhone: values.telephone,
          statusRefund: values.status_refund,
          voided: values.voided,
          chargeBack: values.chargeback,
          reportError: "",
        };
      } else {
        remarkDataArr = {
          orderId: OrderDetails?.id,
          remark: values.remark,
          whatsApp: values.whatsapp,
          telePhone: values.telephone,
          statusRefund: values.status_refund,
          voided: values.voided,
          chargeBack: values.chargeback,
          reportError: "Y",
        };
      }
      dispatch(addNewRemark(remarkDataArr))
        .unwrap()
        .then((res) => {
          resetForm();
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
            dispatch(getOrderDetailsByOrderId(orderId));
          }
        })
        .catch((err) => {
          if (err.status === 0) {
            toast.error(`${err.message}`, {
              className: "toast-message",
            });
          }
        });
    },
  });

  const handleOrderStatus = (e) => {
    const data = {
      orderId: OrderDetails?.id,
      permanentDeletedFlag: false,
      updatingStatus: e.target.value,
      email: OrderDetails?.email,
    };
    dispatch(ChangeOrderStatus(data))
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          toast.success(`${res.message}`, {
            className: "toast-message",
          });
          e.target.blur();
          dispatch(getAllFolderCount());
          dispatch(
            getOrdersList({
              orderName: "refundorder",
              page: page,
              perPage: limit,
            })
          );
        }
      });
  };

  return (
    <StyledOrderBlock>
      <h2>Add Comment Below</h2>
      <form onSubmit={handleSubmit}>
        <textarea
          name="remark"
          style={{ display: "inline-block" }}
          className="form-control"
          defaultValue={values.remark}
          onChange={handleChange}
          onBlur={handleBlur}
        ></textarea>
        <p>{errors.remark}</p>

        <label>
          {" "}
          <input type="hidden" name="check" value="" className="form-control" />
        </label>
        <div className="checkbox">
          <label htmlFor="whatsapp-id" className="label-bold">
            <input
              type="checkbox"
              name="whatsapp"
              id="whatsapp-id"
              onChange={() => setFieldValue("whatsapp", "Y")}
            />
            <span></span>
            Whatsapp
          </label>

          <label htmlFor="telephone-id" className="label-bold">
            <input
              type="checkbox"
              name="telephone"
              id="telephone-id"
              onChange={() => setFieldValue("telephone", "Y")}
            />
            <span></span>
            Telephone
          </label>

          <label htmlFor="statusRefund-id" className="label-bold">
            <input
              type="checkbox"
              name="status_refund"
              id="statusRefund-id"
              onChange={() => setFieldValue("status_refund", "Y")}
            />
            <span></span> Refund
          </label>

          <label htmlFor="voided-id" className="label-bold">
            <input
              type="checkbox"
              name="voided"
              id="voided-id"
              onChange={() => setFieldValue("voided", "Y")}
            />
            <span></span>
            Voided
          </label>

          <label htmlFor="chargeback-id" className="label-bold">
            <input
              type="checkbox"
              name="chargeback"
              id="chargeback-id"
              onChange={() => setFieldValue("chargeback", "Y")}
            />
            <span></span>
            Chargeback
          </label>
        </div>
        <button
          // type="submit"
          className="blue-btn"
          value="comment"
          onClick={(event) => {
            setBtnClicked("saveComment");
          }}
        >
          Save Comment
        </button>
        <button
          type="button"
          className="blue-btn"
          value="Refunds"
          id="Refunds"
          onClick={handleOrderStatus}
        >
          {" "}
          Move to Refund
        </button>

        {OrderDetails?.doc_uploaded === 0 &&
        (OrderDetails?.order_change_status === null ||
          OrderDetails?.order_change_status === "") ? (
          <>
            <button
              type="button"
              className="blue-btn"
              value="Awiating Response"
              id="Awiating Response"
              onClick={handleOrderStatus}
            >
              Move to Awaiting Response
            </button>

            <button
              type="button"
              className="blue-btn"
              value="Deleted Orders"
              id="Deleted Orders"
              onClick={handleOrderStatus}
            >
              Move to Delete
            </button>
          </>
        ) : OrderDetails?.doc_uploaded === 1 &&
          OrderDetails?.uploadDoc[0]?.is_downloaded === 0 &&
          (OrderDetails?.order_change_status === null ||
            OrderDetails?.order_change_status === "") ? (
          <>
            <button
              type="button"
              className="blue-btn"
              value="Awiating Response"
              id="Awiating Response"
              onClick={handleOrderStatus}
            >
              Move to Awaiting Response
            </button>

            <button
              type="button"
              className="blue-btn"
              value="Deleted Orders"
              id="Deleted Orders"
              onClick={handleOrderStatus}
            >
              Move to Delete
            </button>
            <button
              type="button"
              className="blue-btn"
              value="completed"
              id="completed"
              onClick={handleOrderStatus}
            >
              Move to Completeded Orders
            </button>
          </>
        ) : OrderDetails?.doc_uploaded === 1 &&
          OrderDetails?.uploadDoc[0]?.is_downloaded === 1 &&
          (OrderDetails?.order_change_status === null ||
            OrderDetails?.order_change_status === "") ? (
          <>
            <button
              type="button"
              className="blue-btn"
              value="Deleted Orders"
              id="Deleted Orders"
              onClick={handleOrderStatus}
            >
              Move to Delete
            </button>
            <button
              className="blue-btn"
              value="error"
              onClick={(event) => {
                setBtnClicked("reportError");
              }}
            >
              {" "}
              Submit Error
            </button>
          </>
        ) : OrderDetails?.order_change_status === "Deleted Orders" ? (
          <>
            <button
              type="button"
              className="blue-btn"
              value="Awiating Response"
              id="Awiating Response"
              onClick={handleOrderStatus}
            >
              Move to Awaiting Response
            </button>

            <button
              type="button"
              className="blue-btn"
              value="completed"
              id="completed"
              onClick={handleOrderStatus}
            >
              Move to Completeded Orders
            </button>
          </>
        ) : OrderDetails?.order_change_status === "Awiating Response" ? (
          <>
            <button
              type="button"
              className="blue-btn"
              value="completed"
              id="completed"
              onClick={handleOrderStatus}
            >
              Move to Completeded Orders
            </button>

            <button
              type="button"
              className="blue-btn"
              value="Deleted Orders"
              id="Deleted Orders"
              onClick={handleOrderStatus}
            >
              Move to Delete
            </button>
            <button
              className="blue-btn"
              value="error"
              onClick={(event) => {
                setBtnClicked("reportError");
              }}
            >
              {" "}
              Submit Error
            </button>
          </>
        ) : OrderDetails?.order_change_status === "Contact Customer" ? (
          <>
            <button
              type="button"
              className="blue-btn"
              value="Awiating Response"
              id="Awiating Response"
              onClick={handleOrderStatus}
            >
              Move to Awaiting Response
            </button>

            <button
              type="button"
              className="blue-btn"
              value="Contact Customer Called"
              id="Contact Customer Called"
              onClick={handleOrderStatus}
            >
              Move to Customer Called
            </button>

            <button
              type="button"
              className="blue-btn"
              value="Deleted Orders"
              id="Deleted Orders"
              onClick={handleOrderStatus}
            >
              Move to Delete
            </button>
            <button
              className="blue-btn"
              value="error"
              onClick={(event) => {
                setBtnClicked("reportError");
              }}
            >
              {" "}
              Submit Error
            </button>
          </>
        ) : OrderDetails?.order_change_status === "Refunds" ||
          OrderDetails?.order_change_status === "Complete Refunds" ? (
          <>
            <button
              type="button"
              className="blue-btn"
              value="Awiating Response"
              id="Awiating Response"
              onClick={handleOrderStatus}
            >
              Move to Awaiting Response
            </button>
            <button
              type="button"
              className="blue-btn"
              value="completed"
              id="completed"
              onClick={handleOrderStatus}
            >
              Move to Completeded Orders
            </button>

            <button
              type="button"
              className="blue-btn"
              value="Deleted Orders"
              id="Deleted Orders"
              onClick={handleOrderStatus}
            >
              Move to Delete
            </button>
          </>
        ) : OrderDetails?.doc_uploaded === 1 ? (
          <button
            className="blue-btn"
            value="error"
            onClick={(event) => {
              setBtnClicked("reportError");
            }}
          >
            {" "}
            Submit Error
          </button>
        ) : (
          ""
        )}
      </form>
    </StyledOrderBlock>
  );
};

export default AddComment;
