import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import {
  SearchField,
  ButtonWrapper,
  StyledDataTable,
  StyledParentDiv,
  StyledSearch,
  StyledButton,
} from "./style";
import { Link } from "react-router-dom";
import { encryptVal, titleCase } from "../../utility/utility";
import Modal from "../Modal/Modal";
import rightArrow from "../../assests/images/right-arrow.png";
import moment from "moment";
import { toast } from "react-toastify";
import { DebounceInput } from "react-debounce-input";
import {
  getAllFolderCount,
  deleteOrdersData,
  sendRefundMail,
  handleChange,
  searchOrder,
  getOrdersList,
} from "../../redux/orderSlice";
import Pagination from "../customPagination";
import jwt from "jwt-decode";
const ReactDataTable = ({
  data,
  hideButtonWrapper,
  buttonWrapper,
  topButtonWrapper,
  customColumns,
  customData,
  customOrdersData,
  hideSelectableRows,
  orderName,
  setPage,
  setLimit,
  selectableRowDisabled,
  showCustomSearchInput,
}) => {
  const isLoading = useSelector((state) => state?.order?.loading);
  const token = localStorage.getItem("user");
  let userType;
  if (token !== null) {
    const decodedToken = jwt(token);
    userType = decodedToken?.user?.type;
  }
  hideButtonWrapper =
    typeof hideButtonWrapper !== "undefined" ? hideButtonWrapper : false;
  buttonWrapper =
    typeof buttonWrapper === "undefined" ? (
      <ButtonWrapper>
        {userType === "Manager" || userType === "Admin" ? (
          <div className="row">
            <div className="col-sm-6">
              <button
                type="button"
                name="submit"
                value="Delete"
                className="btn blue-btn"
                onClick={() => setShow(true)}
              >
                Delete Order{" "}
                <span>
                  <img src={rightArrow} alt="rightArrow" />
                </span>
              </button>
            </div>
          </div>
        ) : null}
      </ButtonWrapper>
    ) : typeof buttonWrapper === "string" && buttonWrapper === "refund" ? (
      <ButtonWrapper>
        {userType === "Manager" || userType === "Admin" ? (
          <div className="row">
            <div className="col-sm-6">
              <button
                type="button"
                name="submit"
                value="Delete"
                className="btn blue-btn"
                onClick={() => setShow(true)}
              >
                Delete Order{" "}
                <span>
                  <img src={rightArrow} alt="rightArrow" />
                </span>
              </button>{" "}
              <button
                type="button"
                name="submit"
                value="invoice"
                className="btn blue-btn"
                onClick={() => handleRefundMail()}
                disabled={isLoading}
              >
                Refunded it{" "}
                <span>
                  <img src={rightArrow} alt="rightArrow" />
                </span>
              </button>
            </div>
          </div>
        ) : null}
      </ButtonWrapper>
    ) : (
      buttonWrapper
    );

  topButtonWrapper =
    orderName === "deletedorder" ||
    orderName === "savecontinueorder" ||
    orderName === "refundorder" ? (
      <ButtonWrapper>
        {userType === "Manager" || userType === "Admin" ? (
          <div className="row">
            <div className="col-sm-6">
              <button
                type="button"
                name="submit"
                value="Delete"
                className="btn blue-btn"
                onClick={() => setShow(true)}
              >
                Delete Order{" "}
                <span>
                  <img src={rightArrow} alt="rightArrow" />
                </span>
              </button>
            </div>
          </div>
        ) : null}
      </ButtonWrapper>
    ) : (
      <ButtonWrapper></ButtonWrapper>
    );

  let filteredArray = [];
  let columns;
  let Data = [];
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState();
  const [show, setShow] = useState(false);
  const SelectedData = useSelector((state) => state?.order?.selectedData);
  const count = useSelector((state) => state?.order?.count);
  const twilioLogCount = useSelector((state) => state?.order.twilioLogCount);
  const processingLogCount = useSelector(
    (state) => state?.order.processingLogCount
  );
  const documentLogCount = useSelector(
    (state) => state?.order.documentLogCount
  );
  const [searchText, setSearchText] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [rowLimit, setRowLimit] = useState(10);
  const [filterText, setFilterText] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (orderId) => {
    setShow(true);
    setSelectedData([Number(orderId.slice(4, 10))]);
  };
  let [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (SelectedData && SelectedData.length !== 0) {
      setSelectedData(SelectedData);
    }
  }, [SelectedData]);

  useEffect(() => {
    setCurrentPage(pageNum);
    orderName?.includes("awaiting")
      ? setTotalRows(count?.awaitingOrdersCount)
      : orderName?.includes("completed")
      ? setTotalRows(count?.completedOrdersCount)
      : orderName?.includes("delete")
      ? setTotalRows(count?.deletedOrdersCount)
      : orderName?.includes("contactcustomer")
      ? setTotalRows(count?.contactCustomerOrdersCount)
      : orderName?.includes("new")
      ? setTotalRows(count?.newOrdersCount)
      : orderName?.includes("allorder")
      ? setTotalRows(count?.allOrdersCount)
      : orderName?.includes("pending")
      ? setTotalRows(count?.pendingOrdersCount)
      : orderName?.includes("refund")
      ? setTotalRows(count?.refundOrdersCount)
      : orderName?.includes("savecontinue")
      ? setTotalRows(count?.saveContinueOrdersCount)
      : orderName?.includes("twiliolog")
      ? setTotalRows(twilioLogCount)
      : orderName?.includes("processinglog")
      ? setTotalRows(processingLogCount)
      : orderName?.includes("documentlog")
      ? setTotalRows(documentLogCount)
      : orderName?.includes("stateTerritory")
      ? setTotalRows(data?.length)
      : orderName?.includes("contactcallcustomer")
      ? setTotalRows(count?.contactCallOrdersCount)
      : setTotalRows(0);
  }, [pageNum, count, orderName]);

  const handlePageChange = (page) => {
    setPage(page);
    setPageNum(page);
  };

  const handleRowsChange = (limit) => {
    if (limit === "All") {
      setLimit(totalRows);
      setRowLimit(totalRows);
    } else {
      setLimit(limit);
      setRowLimit(limit);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    let searchData = {
      start: pageNum,
      end: rowLimit,
      searchVal: e.target.value,
      folderName: orderName,
    };
    if (e.target.value !== "") {
      dispatch(searchOrder(searchData));
    } else {
      dispatch(
        getOrdersList({
          orderName: orderName,
          page: pageNum,
          perPage: rowLimit,
        })
      );
    }
  };

  const handleRefundMail = () => {
    if (
      selectedData !== null &&
      selectedData.length !== 0 &&
      selectedData !== "undefined"
    ) {
      dispatch(sendRefundMail(selectedData))
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
            dispatch(
              getOrdersList({
                orderName: "refundorder",
                page: pageNum,
                perPage: rowLimit,
              })
            );
          } else {
            if (res.status === 0) {
              toast.error(`${res.message}`, {
                className: "toast-message",
              });
            }
          }
        });
    }
  };

  const deleteOrder = () => {
    let data = {
      orderId: selectedData,
      permanentDeletedFlag:
        orderName === "deletedorder" || orderName === "savecontinueorder"
          ? true
          : false,
      updatingStatus: "Deleted Orders",
    };
    dispatch(deleteOrdersData(data))
      .then((res) => {
        handleClose();
        if (res.payload) {
          toast.success(`Order has been deleted successfully`, {
            className: "toast-message",
          });
          dispatch(
            getOrdersList({
              orderName: orderName,
              page: pageNum,
              perPage: rowLimit,
            })
          );
          dispatch(getAllFolderCount());
        } else {
          toast.error(`${res.message}`, {
            className: "toast-message",
          });
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          toast.success(`${err.message}`, {
            className: "toast-message",
          });
        }
      });
  };

  if (
    data &&
    !customData &&
    typeof data !== "undefined" &&
    typeof data !== null &&
    data?.length !== 0
  ) {
    Data = data?.map((item) => {
      return {
        id: `${item?.pre_id ? item?.pre_id : ""}${item?.id}`,
        name: `${item?.firstName} ${item.lastName}`,
        email: item?.email,
        date: item?.created ? moment(item?.created).format("MM-DD-YYYY") : "-",
        time: item?.us_time ? item?.us_time : "-",
        status: item?.order_change_status
          ? titleCase(item?.order_change_status)
          : item?.order_change_status === null ||
            item?.order_change_status === ""
          ? "New Orders"
          : "-",
        assignto: item?.assign_to ? item?.assign_to : "-",
        Id: item?.id,
        orderId: `${item?.pre_id ? item?.pre_id : ""}${item?.order_id}`,
        customerName: item?.customer_name,
        teamMemberName: item?.team_member_name,
        customerEmailAddress: item?.customer_email_address,
        teamMemberIPAddress: item?.team_member_ip_address,
        createTs: item?.create_ts,
        processingStatus: item?.processing_status,
        customerEmail: item?.customer_email,
        phoneNumber: item?.phone_number,
        message: item?.message,
        folderName: item?.folder_name,
        linkType: item?.link_type,
        ProcessingDate: item?.created_at,
        TwilioDate: item?.create_ts,
        state: item?.StateList ? item?.StateList : 'Other',
        statesCount: item?.count,
      };
    });
  } else if (typeof customData !== "undefined" && customData?.length !== 0) {
    Data = customData?.map((item) => {
      return {
        id: `${item?.pre_id ? item?.pre_id : ""}${item?.id}`,
        name: `${item?.firstName} ${item.lastName}`,
        email: item?.email,
        phone: item?.phoneNumber3 ? item?.phoneNumber3 : "-",
        date: item?.created,
        refundDate: item?.refund_date
          ? moment(item?.refund_date).format("MM-DD-YYYY HH:mm:ss")
          : "-",
        time: item?.us_time ? item?.us_time : "-",
        ip: item?.ip ? item?.ip : "-",
        assignto: item?.assign_to ? item?.assign_to : "-",
        status:
          item?.order_change_status === "Refunds"
            ? "Refund Incomplete"
            : item?.order_change_status === "Complete Refunds"
            ? "Refund Complete"
            : item?.order_change_status
            ? titleCase(item?.order_change_status)
            : (item?.order_change_status === null ||
                item?.order_change_status === "") &&
              item?.doc_uploaded === 0
            ? "New Orders"
            : item?.uploadDoc &&
              (item?.order_change_status === null ||
                item?.order_change_status === "") &&
              item?.doc_uploaded === 1 &&
              item?.uploadDoc[0]?.is_downloaded === 0
            ? "Pending Orders"
            : item?.uploadDoc &&
              (item?.order_change_status === null ||
                item?.order_change_status === "") &&
              item?.doc_uploaded === 1 &&
              item?.uploadDoc[0]?.is_downloaded === 1
            ? "Completed"
            : "-",
      };
    });
  } else if (
    typeof customOrdersData !== "undefined" &&
    customOrdersData?.length !== 0
  ) {
    Data = customOrdersData?.map((item, index) => {
      return {
        id: item?.id,
        name: item?.name,
        email: item?.email,
        password: item?.password,
        type: item?.type,
        matchOrders:
          item?.applicationModel && item?.applicationModel.length !== 0
            ? item?.applicationModel[0]?.rowCount
            : 0,
        errorListings:
          item?.applicationModel && item?.applicationModel[0]?.error > 0
            ? `Order Id's  ${item?.applicationModel[0]?.error}`
            : "No Records",
      };
    });
  }

  if (filterText !== "") {
    filteredArray = Data?.filter(
      (item) =>
        item?.id?.toLowerCase().includes(filterText?.toLocaleLowerCase()) ||
        item?.orderId
          ?.toLowerCase()
          .includes(filterText?.toLocaleLowerCase()) ||
        item?.customerName
          ?.toLowerCase()
          .includes(filterText?.toLocaleLowerCase()) ||
        item?.customer_email_address
        ?.toLowerCase()
        .includes(filterText?.toLocaleLowerCase()) ||
        item?.teamMemberIPAddress
        ?.toLowerCase()
        .includes(filterText?.toLocaleLowerCase()) ||
        item?.createTs
        ?.toLowerCase()
        .includes(filterText?.toLocaleLowerCase()) ||
        item?.phoneNumber
          ?.toLowerCase()
          .includes(filterText?.toLocaleLowerCase()) ||
        item?.folderName
          ?.toLowerCase()
          .includes(filterText?.toLocaleLowerCase()) ||
        item?.teamMemberName
          ?.toLowerCase()
          .includes(filterText?.toLocaleLowerCase()) ||
        item?.processingStatus
          ?.toLowerCase()
          .includes(filterText?.toLocaleLowerCase()) ||
        item?.customerEmail
          ?.toLowerCase()
          .includes(filterText?.toLocaleLowerCase())
    ).map((item) => {
      return {
        Id: item?.Id,
        orderId: item?.orderId,
        customerEmailAddress: item?.customerEmailAddress,
        teamMemberIPAddress: item?.teamMemberIPAddress,
        createTs: item?.createTs,  
        customerName: item?.customerName,
        phoneNumber: item?.phoneNumber,
        message: item?.message,
        folderName: item?.folderName,
        linkType: item?.linkType,
        teamMemberName: item?.teamMemberName,
        processingStatus: item?.processingStatus,
        customerEmail: item?.customerEmail,
        Date: item?.Date,
      };
    });
    Data = filteredArray;
  }

  if (!customColumns) {
    columns = [
      {
        name: "Order Id",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "Name",
        width: "15rem",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Email",
        width: "15rem",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "Date",
        selector: (row) => row.date,
        sortable: true,
      },
      {
        name: "Time",
        selector: (row) => row.time,
        sortable: true,
      },
      {
        name: "Status",
        selector: (row) => row.status,
        sortable: true,
      },
      {
        name: "Assign To",
        selector: (row) => row.assignto,
        sortable: true,
      },
      {
        name: "Action",
        width: "15rem",
        selector: (row) =>
          Data?.length ? (
            <span>
              <Link
                to={`/admin/view-order/${encryptVal(row?.id?.slice(4, 10))}`}
                style={{
                  border: "1px solid #18f",
                  borderRadius: "5px",
                  padding: " 0 5px",
                  minWidth: "80px",
                  display: "inline-block",
                  textAlign: "center",
                  height: "40px",
                  lineHeight: "40px",
                  color: "#111",
                  margin: "5px",
                  textDecoration: "none",
                }}
                className="blue-border"
              >
                View
              </Link>{" "}
              {userType === "Manager" || userType === "Admin" || orderName === "contactcallcustomerorder" ? (
                <Link
                  to="#"
                  onClick={() => handleShow(row?.id)}
                  style={{
                    textDecoration: "none",
                    border: "1px solid #e92a11",
                    borderRadius: "5px",
                    padding: " 0 5px",
                    minWidth: "80px",
                    display: "inline-block",
                    textAlign: "center",
                    height: "40px",
                    lineHeight: "40px",
                    color: " #e92a11",
                    margin: "5px",
                  }}
                >
                  Delete
                </Link>
              ) : null}
            </span>
          ) : (
            " "
          ),
      },
    ];
  } else {
    columns = customColumns;
  }

  const CustomMaterialPagination = () => {
    totalRows = typeof totalRows === "undefined" ? 0 : totalRows;
    
    return (
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={totalRows}
        pageSize={rowLimit}
        onPageChange={(page) => handlePageChange(page)}
        paginationRowsPerPageOptions={["10", "25", "50", "100", "500", "All"]}
        handleRowsChange={handleRowsChange}
      />
    );
  };

  return (
    <>
      <Modal
        show={show}
        close={handleClose}
        size={"s"}
        confirmAlert={true}
        noEvent={() => handleClose()}
        yesEvent={() => deleteOrder()}
      ></Modal>
      <StyledParentDiv>
        {topButtonWrapper}

        {showCustomSearchInput ? (
          <SearchField>
            <input
              type="text"
              id="search_input"
              className="search-bar form-control"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              name="search_input"
              placeholder="Search"
            />
          </SearchField>
        ) : customOrdersData ? null : (
          <SearchField>
            <DebounceInput
              minLength={0}
              debounceTimeout={500}
              type="text"
              id="search_field"
              name="search_field"
              className="search-bar form-control"
              value={searchText}
              onChange={(e) => handleSearch(e)}
              placeholder="Search"
            />
          </SearchField>
        )}
      </StyledParentDiv>

      <StyledDataTable>
        <DataTable
          className="uspassport-custom-tbl"
          selectableRows={
            hideSelectableRows ? false : Data?.length ? true : false
          }
          columns={columns}
          data={Data?.length ? Data : [{}]}
          progressPending={showCustomSearchInput ? false : isLoading}
          fixedHeader={true}
          fixedHeaderScrollHeight="550px"
          pagination={Data?.length && !customOrdersData ? true : false}
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          paginationComponentOptions={{ selectAllRowsItem: true }}
          noDataComponent="No records found"
          onSelectedRowsChange={(e) => dispatch(handleChange(e))}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsChange}
          selectableRowDisabled={selectableRowDisabled}
          paginationComponent={CustomMaterialPagination}
        />
      </StyledDataTable>
      {!Data?.length && isLoading === false && (
        <p style={{ textAlign: "center" }}>{"No data available in table"}</p>
      )}

      {!hideButtonWrapper && buttonWrapper}
    </>
  );
};

export default ReactDataTable;
