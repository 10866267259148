import styled from "styled-components";

export const StyleContainer = styled.div`
  .applicant-detail-block {
    border-top: 1px solid #d8eeff;
    padding: 80px 0px;
  }

  & p {
    color: red;
  }
  .container {
    position: relative;
  }

  .prev-btn img {
    margin-left: 0px;
    margin-right: 10px;
    transform: rotate(180deg);
  }
  .applicant-detail-block .form-head {
    margin-bottom: 30px;
    text-align: center;
  }
  .applicant-detail-block h2 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
  }
  .form-wrapper {
    box-shadow: 0px 0px 15px rgba(17, 136, 255, 0.12);
    background: #fff;
    padding: 30px;
  }
  .form-wrapper label {
    font-weight: 400;
    margin-bottom: 10px;
  }
  .form-wrapper .form-control {
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    box-shadow: none;
    height: 50px;
    padding: 6px 15px;
    font-size: 16px;
    font-weight: 400;
    color: #000c19;
  }
  .form-wrapper .form-control:focus {
    border-color: #0b3157;
  }
  .form-wrapper .form-group {
    margin-bottom: 30px;
  }
  .form-wrapper select.form-control {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(../images/down.png);
    background-repeat: no-repeat;
    background-position: 95%;
  }
  .form-wrapper .form-control.height-box {
    background-position: 85%;
  }
  .popup-icon {
    font-size: 20px;
    margin-left: 5px;
    display: inline-flex;
  }
  .popover {
    min-width: 250px;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
  }
  .form-wrapper .row {
    display: flex;
    flex-wrap: wrap;
  }

  .gender-btn {
    display: flex;
    margin: 0px -10px;
  }
  .gender-btn label {
    width: 50%;
    padding: 0px 10px;
    margin: 0px;
  }
  .gender-btn label span {
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    color: #a5a5a5;
    text-align: center;
    height: 50px;
    line-height: 100%;
    display: block;
    cursor: pointer;
    padding: 0px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gender-btn label input {
    width: 0px;
    height: 0px;
    position: absolute;
    visibility: hidden;
  }
  .gender-btn label input:checked + span {
    border-color: #1188ff;
    color: #fff;
    background-color: #1188ff;
  }
  .form-wrapper .input-group {
    display: flex;
  }
  .form-wrapper .input-group > span {
    background: #e9ecef;
    color: #a5a5a5;
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #e1e1e1;
    line-height: 50px;
    height: 50px;
    padding: 0px 10px;
    border-left: 0px;
  }
  .form-wrapper .input-group .input-group-addon {
    cursor: pointer;
    width: 50px;
  }
  .form-btns {
    text-align: center;
    margin: 35px 0px 15px;
  }
  .form-btns .blue-btn {
    margin: 0px 10px;
  }
  .form-wrapper h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .blue-btn {
    background-color: #18f;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 30px;
    display: inline-block;
  }
`;
