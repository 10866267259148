import React from "react";
import { StyleContainer } from "./style";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { trackCustomerOrder } from "../../redux/orderSlice";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";

const FoundOrder = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const initialValues = {
    orderId: "47318",
    email: "",
  };

  // const Id = useSelector((state) => state?.order?.orderId);
  // const [emailId, setEmailId] = useState(OrderDetails?.email);

  const foundOrderSchema = yup.object({
    // orderId: yup.string().required("Please enter your Order Id"),
    email: yup.string().required("Please enter your Email Id"),
  });

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: foundOrderSchema,
    onSubmit: (values) => {
      dispatch(trackCustomerOrder(values))
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
            // navigate(`/download-history/${Id}`);
          }
        });
    },
  });

  return (
    <StyleContainer>
      <div className="content">
        <div className="applicant-detail-block">
          <div className="container">
            <div className="form-head">
              <h2>
                To download your documents from our portal you will need to
                enter the email address you used when you completed your
                transaction.
              </h2>
            </div>
            <div className="form-wrapper">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="form-group">
                      <h3>Order ID</h3>
                      <input
                        type="text"
                        placeholder="Order ID"
                        name="orderId"
                        value={values.orderId}
                        // value={"2000300095"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly
                        className="form-control"
                      />
                      <input
                        type="hidden"
                        className="textbox"
                        name="action"
                        value="1"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="form-group">
                      <h3>Email ID</h3>
                      <input
                        type="email"
                        placeholder="Email ID"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                      />
                      <p>{errors.email}</p>
                    </div>
                  </div>
                </div>
                <div className="form-btns">
                  <button
                    className="blue-btn"
                    id="submit-form"
                    type="submit"
                    value="Search Order"
                    style={{ border: "none" }}
                  >
                    Search Order
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </StyleContainer>
  );
};

export default FoundOrder;
