import styled from "styled-components";

export const StyledContainer = styled.div``;

export const StyledPageTitle = styled.div`
  background: #0b3157;
  color: #fff;
  border-radius: 5px;
  padding: 19px 25px;
  margin-bottom: 20px;

  & h1 {
    font-size: 20px;
    color: #ffffff;
    font-weight: 600;
  }
`;

export const StyledOrderBlock = styled.div`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding: 15px 25px;
  margin-bottom: 20px;

  @media only screen and (max-width: 767px) {
    padding: 15px;
  }

  .table-block table {
    width: 100%;
  }

  .table-block table th {
    font-weight: 600;
    font-size: 18px;
    padding: 10px;

    @media only screen and (max-width: 767px) {
      font-weight: 600;
      font-size: 15px;
      padding: 5px;
    }
  }
  .table-block table td {
    border-top: 1px solid #e8f5ff;
    font-size: 16px;
    padding: 10px;
    vertical-align: top;
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      padding: 5px;
    }
  }
  .table-block table tr:nth-child(even) td {
    background: #f9fcff;
  }

  @media only screen and (max-width: 767px) {
    .table-block.table-responsive {
      border: 0px;
    }
  }
  & div:first-child {
    white-space: pre-line;
    overflow: auto;
    text-overflow: ellipsis;
  }
`;
